import React from "react";

import GroupChannel from "@sendbird/uikit-react/GroupChannel";
import GroupChannelList from "@sendbird/uikit-react/GroupChannelList";
import ChannelSettings from "@sendbird/uikit-react/ChannelSettings";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";

import { EnvironmentConfiguration } from "configuration/environment.configuration";
import { useAuthentication } from "app/contexts/authentication/use-authentication.hook";

import "./style.scss";
import "@sendbird/uikit-react/dist/index.css";

export default function Chat() {
  const { state: authenticationState } = useAuthentication();

  const [showSettings, setShowSettings] = React.useState(false);
  const [currentChannelUrl, setCurrentChannelUrl] = React.useState("");

  const user = authenticationState?.user;

  return (
    <SendbirdProvider
      appId={EnvironmentConfiguration.SENDBIRD_APP_ID}
      userId={`${user?.id}`}
      nickname={user?.name}
    >
      <div className="chat">
        <div className="sendbird-app__wrap">
          <div className="sendbird-app__channellist-wrap">
            <GroupChannelList
              selectedChannelUrl={currentChannelUrl}
              onChannelCreated={(channel) => {
                setCurrentChannelUrl(channel.url);
              }}
              onChannelSelect={(channel) => {
                setCurrentChannelUrl(channel?.url || "");
              }}
            />
          </div>
          <div className="sendbird-app__conversation-wrap">
            <GroupChannel
              channelUrl={currentChannelUrl}
              onChatHeaderActionClick={() => {
                setShowSettings(true);
              }}
            />
          </div>

          {showSettings && (
            <div className="sendbird-app__settingspanel-wrap">
              <ChannelSettings
                channelUrl={currentChannelUrl}
                onCloseClick={() => {
                  setShowSettings(false);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </SendbirdProvider>
  );
}
