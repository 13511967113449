import { EnvironmentTypes } from "app/enums/environment-type.enum";

export const IS_DEVELOPMENT_ENVIRONMENT =
  process.env.REACT_APP_ENV === EnvironmentTypes.DEVELOPMENT;

export const IS_PRODUCTION_ENVIRONMENT =
  process.env.REACT_APP_ENV === EnvironmentTypes.PRODUCTION;

export const EnvironmentConfiguration = Object.freeze({
  API_URL: process.env.REACT_APP_API_URL,

  USER_AUTH_ID: process.env.REACT_APP_USER_AUTH_ID,
  USER_AUTH_SECRET: process.env.REACT_APP_USER_AUTH_SECRET,

  USER_AUTH_CLIENT_ID: process.env.REACT_APP_USER_AUTH_CLIENT_ID,
  USER_AUTH_CLIENT_SECRET: process.env.REACT_APP_USER_AUTH_CLIENT_SECRET,

  AUTHENTICATION_REDIRECT_URL:
    process.env.REACT_APP_AUTHENTICATION_REDIRECT_URL,

  USER_ANONYMOUS_CLIENT_ID: process.env.REACT_APP_USER_ANONYMOUS_CLIENT_ID,
  USER_ANONYMOUS_CLIENT_SECRET:
    process.env.REACT_APP_USER_ANONYMOUS_CLIENT_SECRET,

  TEST_USER_EMAIL: IS_DEVELOPMENT_ENVIRONMENT
    ? process.env.REACT_APP_TEST_USER_EMAIL ?? ""
    : undefined,
  TEST_USER_PASSWORD: IS_DEVELOPMENT_ENVIRONMENT
    ? process.env.REACT_APP_TEST_USER_PASSWORD ?? ""
    : undefined,
  TEST_USER_NAME: IS_DEVELOPMENT_ENVIRONMENT
    ? process.env.REACT_APP_TEST_USER_NAME ?? ""
    : undefined,
  TEST_USER_PHONE: IS_DEVELOPMENT_ENVIRONMENT
    ? process.env.REACT_APP_TEST_USER_PHONE ?? ""
    : undefined,

  ALGOLIA_APPLICATION_ID: process.env.REACT_APP_ALGOLIA_APPLICATION_ID ?? "",
  ALGOLIA_SEARCH_ONLY_API_KEY:
    process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY ?? "",

  STRAPI_URL: process.env.REACT_APP_STRAPI_URL,
  STRAPI_TOKEN: process.env.REACT_APP_STRAPI_TOKEN,

  MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,

  CLEVERTAP_KEY: process.env.REACT_APP_CLEVERTAP_KEY,

  SENDBIRD_APP_ID: process.env.REACT_APP_SENDBIRD_APP_ID ?? "",
});
