export enum AppRoutes {
  NotFound = "*",

  SignIn = "/sign-in",
  SignUp = "/sign-up",

  SignInForm = "/sign-in-form",
  SignUpForm = "/sign-up-form",

  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",

  Download = "/download",

  CompleteSignUp = "/complete-sign-up",

  FreeArticle = `/conteudos/livre/:id`,
  OdNewsArticle = `/conteudos/odnews/:id`,
  ClassifiedsArticle = `/conteudos/classifieds/:id`,
  TaskArticle = `/conteudos/tarefa/:id`,

  SalesContact = "/sales-contact",

  Chat = "/chat",
}
